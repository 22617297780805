import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import { Feature } from '../components/feature';
import { Logo, LogoVertical } from '../components/logo';
import {
  MenuIcon,
  MenuCloseIcon,
  CommLabIcon,
  LinkedIcon,
  MailIcon,
  DownIcon,
  FbIcon,
  AtIcon,
  PhoneIcon,
  MapIcon,
} from '../components/icons';
import { start } from '../components/snow';
import { throttle } from '../components/utils';

import '../styles/style.scss';

function scrollToTargetAdjusted(element) {
  const headerOffset = 50;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
}

function getBrowserLocales(options = {}) {
  const defaultOptions = {
    languageCodeOnly: true,
  };
  const opt = {
    ...defaultOptions,
    ...options,
  };
  const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;
  if (!browserLocales) {
    return undefined;
  }
  return browserLocales.map((locale) => {
    const trimmedLocale = locale.trim();
    return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
  });
}

// data
const menu = [
  {
    title: 'Home',
    link: '/',
  },
  {
    title: 'References',
    link: '/reference',
  },
  {
    title: 'Management',
    link: '/management',
  },
  {
    title: 'Kontakty',
    link: '/kontakt',
  },
];

const features = [
  {
    title: 'Public relations',
    symbol: 'Pr',
    number: '59',
    size: 'lg',
  },
  {
    title: 'Social media',
    symbol: 'Sm',
    number: '62',
    size: 'sm',
  },
  {
    title: 'Media training',
    symbol: 'Mt',
    number: '109',
    size: 'sm',
  },
  {
    title: 'Crisis communication',
    symbol: 'C',
    number: '6',
    size: 'mid',
  },
  {
    title: 'Events',
    symbol: 'Es',
    number: '99',
    size: 'sm',
  },
  {
    title: 'Internal communication',
    symbol: 'I',
    number: '53',
    size: 'mid',
  },
  {
    title: 'Policy PR',
    symbol: 'Pi',
    number: '84',
    size: 'sm',
  },
  {
    title: 'Strategic Communication',
    symbol: 'Sk',
    number: '64',
    size: 'sm',
  },
];

const references = [
  { link: '/reference/barva/logo_facebook.png', title: 'facebook' },
  { link: '/reference/barva/logo_wolt.png', title: 'wolt' },
  { link: '/reference/barva/logo_westinghouse.png', title: 'westinghouse' },
  { link: '/reference/barva/logo_novavax.png', title: 'novavax' },
  { link: '/reference/barva/logo_das.png', title: 'das' },
  { link: '/reference/barva/logo_generaldynamics.png', title: 'generaldynamics' },
  { link: '/reference/barva/logo_skrivanek.png', title: 'skrivanek' },
  { link: '/reference/barva/logo_swappie.png', title: 'swappie' },
  { link: '/reference/barva/logo_es.png', title: 'es' },
  { link: '/reference/barva/logo_energytrading.png', title: 'energytrading' },
  { link: '/reference/barva/logo_premiot.png', title: 'premiot' },
  { link: '/reference/barva/logo_topstone.png', title: 'topstone' },
  { link: '/reference/barva/logo_adra.png', title: 'adra' },
  { link: '/reference/barva/logo_hencefort.png', title: 'hencefort' },
  { link: '/reference/barva/logo_defakto.png', title: 'defakto' },
  { link: '/reference/barva/logo_dvur.png', title: 'dvur' },
  { link: '/reference/barva/logo_eit.png', title: 'eit' },
  { link: '/reference/barva/logo_elis.png', title: 'elis' },
  { link: '/reference/barva/logo_exporeal.png', title: 'exporeal' },
  { link: '/reference/barva/logo_lir.png', title: 'lir' },
  { link: '/reference/barva/logo_moon.png', title: 'moon' },
  { link: '/reference/barva/logo_praha.png', title: 'praha' },
  { link: '/reference/barva/logo_praha1.png', title: 'praha1' },
  { link: '/reference/barva/logo_sanifair.png', title: 'sanifair' },
  { link: '/reference/barva/logo_scmbd.png', title: 'scmbd' },
];

const Menu = ({ visible, toggle, toggleVisible, scrolling, aboutRef, referencesRef, contactRef }) => {
  return (
    <>
      <MenuIcon
        onClick={() => {
          toggle(false);
          toggleVisible(true);
          console.log('Toggle menu', visible);
        }}
      />
      {scrolling && (
        <nav className={`menu ${scrolling ? 'visible' : ''}`}>
          <div className="menu-logo">
            <Logo alt="Commlab" />
            <LogoVertical alt="Commlab" />
          </div>
          <ul className={`${visible ? 'visible-items' : ''}`}>
            <li
              className="grow2"
              onClick={() => {
                scrollToTargetAdjusted(aboutRef.current);
                toggle(false);
                toggleVisible(false);
                // aboutRef.current.scrollIntoView();
              }}
            >
              About Commlab
            </li>
            <li
              className="grow2"
              onClick={() => {
                scrollToTargetAdjusted(referencesRef.current);
                toggle(false);
                toggleVisible(false);
                // referencesRef.current.scrollIntoView();
              }}
            >
              References
            </li>
            <li
              className="grow2"
              onClick={() => {
                scrollToTargetAdjusted(contactRef.current);
                toggle(false);
                toggleVisible(false);
                // contactRef.current.scrollIntoView();
              }}
            >
              Contacts
            </li>
          </ul>
        </nav>
      )}
    </>
  );
};

const Li = ({ link }) => {
  return (
    <a className="icon linked grow" href={link} target="_blank">
      <LinkedIcon />
    </a>
  );
};

const Mail = ({ link }) => {
  return (
    <a className="icon mail grow" href={link} target="_blank">
      <MailIcon />
    </a>
  );
};

const availableLangs = ['en', 'cs'];

// markup
const IndexPage = () => {
  const aboutRef = useRef(null);
  const referencesRef = useRef(null);
  const contactRef = useRef(null);
  const [lang, toggleLang] = useState('cs');
  const [scrolling, setScrolling] = useState(false);
  const [started, setStarted] = useState(false);
  // const [scrollTop, setScrollTop] = useState(0);
  const [visible, toggle] = useState(false);

  useEffect(() => {
    function onScroll() {
      if (visible) {
        return;
      }
      console.log('on scroll');
      let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
      if (currentPosition > 100) {
        // downscroll code
        if (!scrolling) {
          setScrolling(true);
          console.log('on scroll over');
        }
      } else {
        // upscroll code
        if (scrolling) {
          setScrolling(false);
          console.log('on scroll below');
        }
      }
      // setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }

    const throttledOnSroll = throttle(onScroll, 300);

    window.addEventListener('scroll', throttledOnSroll);

    return () => window.removeEventListener('scroll', throttledOnSroll);
  });

  useEffect(() => {
    // Update the document title using the browser API
    const locales = getBrowserLocales();
    // Start animation
    if (!started) {
      start();
      setStarted(true);
    }

    // if (lang === locales[0]) {
    //   return;
    // } else if (locales[0] === 'en') {
    //   window.location.href = '/en';
    // }
    //
  });

  return (
    <>
      <Helmet>
        <script src="//cdn.cookie-script.com/s/0b1a573414b0531f46d61fed9cf8d65c.js" />
      </Helmet>
      <main className={`${scrolling ? 'scrolled' : ''}`}>
        <div id="snow"></div>
        <Menu
          toggle={(next) => {
            setScrolling(!next);
          }}
          toggleVisible={(next) => {
            toggle(next);
          }}
          visible={visible}
          scrolling={scrolling}
          aboutRef={aboutRef}
          referencesRef={referencesRef}
          contactRef={contactRef}
        />
        <section ref={aboutRef} className="section landing">
          <article>
            <h1>
              <Logo alt="Commlab" />
            </h1>
            <p>
              We are a communications and consulting agency operating in Prague and Brno. Since 2015 we have been
              helping individuals as well as local and international companies not only in the Czech, but also the
              Slovak market.
            </p>
            <p>
              We focus primarily on clients from the fields of technology, development, design, and healthcare. Our goal
              is to become a business partner of our clients to help them grow further. Therefore, we always look for
              unique and creative solutions that are tailor-made for specific projects.
            </p>
            <p>
              We are pleased to be able to prove this, in part by winning PR awards from the Association of PR Agencies.
            </p>
          </article>
          {!scrolling && <DownIcon className="down-icon bounce" />}
        </section>
        <section className="features">
          <div className="wrap" style={{}}>
            {features.map(({ title, symbol, number, size }, i) => (
              <Feature
                key={i}
                title={title}
                symbol={symbol}
                number={number}
                className={`feature-${number}`}
                size={size}
              />
            ))}
          </div>
        </section>
      </main>
      <section ref={referencesRef} className="references">
        <h2>Our references</h2>
        <Container>
          <Row>
            <Col>
              <ul>
                {references.map((ref, i) => (
                  <li key={i} className="reference">
                    <img src={ref.link} alt={ref.title} />
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <section ref={contactRef} className="leads">
        <h2>Management</h2>
        <Container>
          <Row>
            <Col md={4}>
              <div className="lead">
                <div className="frame">
                  <Li link="https://cz.linkedin.com/in/michaela-studena-92513247" />
                  <Mail link="mailto:michaela@commlab.cz" />
                  <img src="/misa.png" alt="Michaela Studená" />
                </div>
                <div className="title">
                  <span className="name">Michaela Studená</span>
                  <br />
                  <span className="role">Managing Partner</span>
                  <br />
                  <span className="email">
                    <a href="mailto:michaela@commlab.cz">michaela@commlab.cz</a>
                  </span>
                  <br />
                  <span className="phone">
                    <a href="tel:+420608307205">+420 608 307 205</a>
                  </span>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="lead">
                <div className="frame">
                  <Li link="https://www.linkedin.com/in/jiri-durovic-69a77583/" />
                  <Mail link="mailto:jiri@commlab.cz" />
                  <img src="/jirka.png" alt="Jiří Ďurovič" />
                </div>
                <div className="title">
                  <span className="name">Jiří Ďurovič</span>
                  <br />
                  <span className="role">Managing Partner</span>
                  <br />
                  <span className="email">
                    <a href="mailto:jiri@commlab.cz">jiri@commlab.cz</a>
                  </span>
                  <br />
                  <span className="phone">
                    <a href="tel:+420725997182">+420 725 997 182</a>
                  </span>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="lead">
                <div className="frame">
                  <Li link="https://www.linkedin.com/in/janskalnik?originalSubdomain=cz" />
                  <Mail link="mailto:honza@commlab.cz" />
                  <img src="/honza.png" alt="Jan Skalník" />
                </div>
                <div className="title">
                  <span className="name">Jan Skalník</span>
                  <br />
                  <span className="role">Partner</span>
                  <br />
                  <span className="email">
                    <a href="mailto:honza@commlab.cz">honza@commlab.cz</a>
                  </span>
                  <br />
                  <span className="phone">
                    <a href="tel:+420775000557">+420 775 000 557</a>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="contact">
          <Container>
            <Row>
              <Col>
                <h3>Contacts</h3>
                <p>If you have any questions, please do not hesitate to contact us.</p>
              </Col>
            </Row>
            <Row className="contactFrame">
              <Col lg={2}></Col>
              <Col lg={4}>
                <p>
                  <AtIcon /> <a href="mailto:michaela@commlab.cz">michaela@commlab.cz</a> <br />
                  <PhoneIcon /> <a href="tel:+420608307205">608 307 205</a> <br />
                  <br />
                  <AtIcon /> <a href="mailto:jiri@commlab.cz">jiri@commlab.cz</a> <br />
                  <PhoneIcon /> <a href="tel:+420725997182">725 997 182</a> <br />
                </p>
                <p>
                  <FbIcon href="https://www.facebook.com/commlabcz/" />
                  <Li link="https://www.linkedin.com/company/commlab/" />
                </p>
              </Col>
              <Col lg={4}>
                <p>
                  <MapIcon />
                  <a href="https://www.google.com/maps/place/%C5%A0aldova+34,+186+00+Karl%C3%ADn/@50.0944423,14.4530265,17z/data=!3m1!4b1!4m5!3m4!1s0x470b94a423878733:0x808375bb9bc2a446!8m2!3d50.0944423!4d14.4552152">
                    Šaldova 34, Praha 8 – Karlín
                  </a>
                  <br />
                  <br />
                  <strong>Billing address:</strong>
                  <br />
                  Pekařova 838/2a, 181 00 Praha 8<br />
                  <br />
                  <strong>IČO</strong>
                  <br />
                  03922367
                  <br />
                  <br />
                  <strong>Language</strong> <a href="/">CZ</a> | <a href="/en">EN</a>
                </p>
              </Col>
              <Col lg={2}></Col>
            </Row>
          </Container>
        </div>
      </section>
      <footer>© {`${new Date().getFullYear()}`} Communication Lab, s. r. o. All rights reserved.</footer>
    </>
  );
};

export default IndexPage;
